import React from 'react';
import './Hero.css'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CountUp from 'react-countup';
const Hero = () => {
  return (
    <div>
      <section className='hero-wrapper'>
        <div className="paddings innerWidth flexCenter hero-container ">
         {/* LEFT SIDE */}
          <div className='flexColStart hero-left'>
            <div className='hero-title'>
              <div className="orange-circle"/>
              <h1>Discover <br/>Most Suitable <br/>Property</h1>
              </div>
              <div className="flexColStart hero-des">
                <span className='secondaryText'>Find a variety of properties that suit you very easilty</span>
                <span className='secondaryText'>Find a variety of properties that suit you very easilty</span>
              </div>
            <div className='flexCenter search-bar'>
            <LocationOnIcon color="primary" style={{ fontSize: 25 }}/>
            <input type="text"/>
            <button className="button">Search</button>
            </div>

            <div className='flexCenter stats'>
              <div className='flexColCenter stat'>
                <span>
                  <CountUp start={8800} end={9000} duration={4}/>
                
                <span>+</span>
                </span>
                <span className='secondaryText'>Premium Products</span>
              </div>
              <div className='flexColCenter stat'>
                <span>
                  <CountUp start={1950} end={9000} duration={4}/>
                
                <span>+</span>
                </span>
                <span className='secondaryText'>Happy customers</span>
              </div>
              <div className='flexColCenter stat'>
                <span>
                  <CountUp end={28} />
               
                <span>+</span>
                </span>
                <span className='secondaryText'>Award Winning</span>
              </div>
            </div>

          

            
          </div>
          {/* RIGHT SIDE */}
          <div className="flexCenter hero-right">
            <div className='image-container'>
              <img src='./hero-image.png' alt=''/>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Hero;
