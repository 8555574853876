import React from 'react';
import './Companies.css'

const Companies = () => {
  return (
    <section className='c-wrapper'>
        <div className='paddings innerwidth flexCenter c-container'>
            <img src="./tower.png"   alt='img'/>
            <img src="./equinix.png"  alt='img' />
            <img src="./realty.png"  alt='img' />
            <img src="./prologis.png"   alt='img'/>
        </div>
    </section>
  );
}

export default Companies;
