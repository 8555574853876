import React from 'react';
import './Contact.css'
import CallIcon from '@mui/icons-material/Call';
import ChatIcon from '@mui/icons-material/Chat';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import MessageIcon from '@mui/icons-material/Message';
const Contact = () => {
  return (
    <section className='C-wrapper'>
        <div className='paddings innerwidth flexCenter C-container '>
            <div className='flexColStart C-left'>
                <span className='orangeText'>Our Contact Us</span>
                <span className='primaryText'>Easy to contact us</span>
                <span className='secondaryText'>We always ready to help by providijng the best services for you. We beleive a  <br/> good blace to live can make your life better</span>
            
          
            <div className='flexColStart contactModes'>
                    {/*first row*/}
                    <div className='flexStart row'>
                        <div className='flexColCenter mode'>
                            <div className='flexStart'>
                                <div className='flexCenter icon'>
                                    <CallIcon style={{ fontSize: '25' }}/>
                                </div>
                                <div className='flexColStart detail'>
                                    <span className='primaryText'>Call</span>
                                    <span className='secondaryText'>021 123 145 14</span>
                                </div>
                            </div>
                            <div className='flexCenter button'>Call Now</div>
                        </div>

                        {/* second mode  */}
                        <div className='flexColCenter mode'>
                            <div className='flexStart'>
                                <div className='flexCenter icon'>
                                    <ChatIcon style={{ fontSize: '25' }}/>
                                </div>
                                <div className='flexColStart detail'>
                                    <span className='primaryText'>Chat</span>
                                    <span className='secondaryText'>021 123 145 14</span>
                                </div>
                            </div>
                            <div className='flexCenter button'>Chat Now</div>
                        </div>
                    </div>

                    <div className='flexStart row'>
                        <div className='flexColCenter mode'>
                            <div className='flexStart'>
                                <div className='flexCenter icon'>
                                    <VideoChatIcon style={{ fontSize: '25' }}/>
                                </div>
                                <div className='flexColStart detail'>
                                    <span className='primaryText'>Vedio Call</span>
                                    <span className='secondaryText'>021 123 145 14</span>
                                </div>
                            </div>
                            <div className='flexCenter button'>Vedio Call Now</div>
                        </div>

                        {/* second mode  */}
                        <div className='flexColCenter mode'>
                            <div className='flexStart'>
                                <div className='flexCenter icon'>
                                    <MessageIcon style={{ fontSize: '25' }}/>
                                </div>
                                <div className='flexColStart detail'>
                                    <span className='primaryText'>Message</span>
                                    <span className='secondaryText'>021 123 145 14</span>
                                </div>
                            </div>
                            <div className='flexCenter button'>Message Now</div>
                        </div>
                    </div>
                    </div>

{/*2nd row*/}
                    


            </div>

            <div className='C-right'>
                <div className='image-container'>
                    <img src='./contact.jpg' alt=''/>
                </div>
            </div>
            </div>
    </section>
  );
}

export default Contact;
